@import '~bulma/bulma';
@import '~animate.css/animate.min.css';

html,
body,
#root {
  height: 100%;
}

.Toastify__toast--error {
  background: #FF0034;

  .Toastify__toast-body {
    font-font: inherit;
    font-weight: 500 !important;
  }
}

.hidden {
  opacity: 0;
}

.title {
  color: #323A45 !important;
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  background: white;

  &__button {
    height: 60px;
    width: 80%;
    max-width: 600px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
    border-width: 0;
  }

  &--visible {
    opacity: 1;
    pointer-events: unset;
  }

  &__entry {
    max-width: 600px;
    width: 80%;
  }

  .input {
    position: relative;
    height: 60px;
    font-size: 24px;
    text-align: center;

    > span {
      position: absolute;
      z-index: 1;
    }
  }

  &__input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    > input:focus {
      border-color: #5FD9FC;
      box-shadow: unset;
      border-width: 2px;
    }
  }

  &__tw {
    position: absolute;
    color: darkgray;
    line-height: 60px;
    font-size: 24px;
    text-align: center;
    width: 100%;
    pointer-events: none;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__close {
    padding: 0;
    background: none;
    border: 0;
    font-size: 25px;
    color: #323A45;
    margin-left: 40px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #5FD9FC;
    }
  }
}

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;

  &__search {
    padding: 0;
    background: none;
    border: 0;
    font-size: 25px;
    color: #323A45;
    margin-right: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #5FD9FC;
    }
  }

  &__logo {
    margin-bottom: 75px;
    width: 100%;
    max-width: 500px;
  }

  &__social {
    position: fixed;
    top: 0;
    left: 0;
    padding-right: 40px;
    padding-left: 40px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    background: white;
    align-items: center;

    > .icon {
      font-size: 30px;
      color: #323A45;

      &:hover {
        color: #5FD9FC;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  &__confirm {
    display: flex;
    justify-content: center;
    flex-direction: column;

    a {
      color: #00d1b2 !important;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    > h1 {
      width: 100%;
    }
  }

  .title {
    max-width: 80vw;

    > span {
      text-decoration: underline;
    }
  }

  &__entry {
    max-width: 600px;
  }

  &__entry,
  &__confirm {
    width: 80%;
  }

  > button {
    height: 60px;
    width: 80%;
    max-width: 600px;
    font-size: 20px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
}
